import React from 'react';
import cardImage from '../assets/img/Dya-25.JPG';
import cardImage2 from '../assets/img/Dya-26.JPG';

const Step1 = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top' alt='dya' style={{ width: '15rem', height: 'auto' }} />
        <img src={cardImage2} class='card-img-top' alt='dya' style={{ width: '15rem', height: 'auto' }} />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>
          <strong>8. </strong>Mal plan
        </h5>
        <p className='card-text'>
          Y pues ahí voy a meter las cuatrooo jajajaja, osea desde el principio yo quería hacer todo bien, digo no es como que sea
          lo más correcto del mundo pero bueno que le hacemos, yo quería hablar con todos bien desde el principio pero
          CIERTAAAAAAA PERSONAAAAAAA me dijo que nel, que nos esperáramos y ahí va Israel todo wey a hacerle caso y bueno, el
          resto es historia jajajaja ya estamos aquí no? Pd: Lección aprendida, ya no te wa hacer caso xD
        </p>
      </div>
    </div>
  );
};

export default Step1;
