import React from 'react';
import cardImage from '../assets/img/Dya-48.JPG';
import cardImage2 from '../assets/img/Dya-1.JPG';

const Step1 = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top' alt='dya' style={{ width: '15rem', height: 'auto' }} />
        <img src={cardImage2} class='card-img-top' alt='dya' style={{ width: '15rem', height: 'auto' }} />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>
          <strong>9. </strong>Pa'tras ni pa'agarrar vuelo
        </h5>
        <p className='card-text'>
          En fin... ya estamos a punto de terminar, tranqui, no desespereis jajajaja la vdd pensé mucho como hacerle o de que
          manera hacerlo, iba a esperar un tiempo más, pero osh jajaja pa que hacer mañana lo que puedes hacer hoy xD? De todas
          maneras ya todo mundo sabe, ya no hay nada que esconder, probablemente no sea del agrado de todo mundo y lo entiendo,
          digo, te llevo 8 años Dx. Pero bueno, daré lo mejor de mí para que esto funciona y te prometo que será increíble!! :3
        </p>
      </div>
    </div>
  );
};

export default Step1;
