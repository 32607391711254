import React from 'react';
import cardImage from '../assets/img/Dya-12.JPG';

const Step1 = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top' alt='dya' />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>
          <strong>5. </strong>Dice la locologa que tarde o temprano llega la aceptación
        </h5>
        <p className='card-text'>
          Pos resulta que en navidad fue cuando acepte que me gustabas, y de todas formas no iba a hacer nadaaaaaa, ese día me la
          pase genial con todos y obvio tmb contigo. No supe que pasaría en ese momento, solo decidí ignorarlo y hacer como si
          nada pasara. Digo... como me podía gustar la hermana de mi mejor amiwaaaaaa Dx
        </p>
      </div>
    </div>
  );
};

export default Step1;
