import React from 'react';
import shrek from '../assets/img/shrek-xD.jpg';

const Inicio = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={shrek} class='card-img-top' alt='dya' />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>Comencemos...</h5>
        <p className='card-text'>
          <ol>
            <li>Primero que nada picale al botón de play que esta a la derecha.</li>
            <li>Tal vez no es la mejor idea, pero bueno aquí vamos xD</li>
            <li>Picale siguiente... (NO le vayas a picar atras eh!)</li>
          </ol>
        </p>
      </div>
    </div>
  );
};

export default Inicio;
