import React from 'react';
import cardImage from '../assets/img/Dya-22.JPG';

const MuyAtras = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top mx-5' alt='dya' style={{ width: 'auto', height: '26rem' }} />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>Dya regandola como siempre xD</h5>
        <p className='card-text'>
          Te dijeeeeeeeeeeee que no le picaras atrás, pero ahí vassssssss, ¿Por qué no me sorprende? jajajajaja orele regresese xD
        </p>
      </div>
    </div>
  );
};

export default MuyAtras;
