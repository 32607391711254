import React from 'react';
import cardImage from '../assets/img/Dya-54.JPG';
import cardImage2 from '../assets/img/Dya-55.JPG';

const Step1 = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top' alt='dya' style={{ width: 'auto', height: '26rem' }} />
        <img src={cardImage2} class='card-img-top' alt='dya' style={{ width: 'auto', height: '26rem' }} />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>
          <strong>1. </strong>Algo inesperado...
        </h5>
        <p className='card-text'>
          Dya... te conozco ya hace como 4 o 5 años, siempre fuiste alguien especial para mí aunque no de esta manera. En realidad
          hasta hace muy poco tiempo te convertiste en una persona increíblemente especial e importante para mí. Sé que tal vez no
          era lo que esperabas tú, yo, ni nadie, simplemente pasó y estoy bien con eso.
        </p>
      </div>
    </div>
  );
};

export default Step1;
