import React from 'react';
import cardImage from '../assets/img/Dya-50.JPG';
import cardImage2 from '../assets/img/Dya-1.JPG';
import logrado from '../assets/img/logrado.jpg';
import plankton from '../assets/img/plankton.jpg';
import moment from 'moment';
import 'moment/locale/es-mx';

const Step1 = () => {
  const [right, setRight] = React.useState(340);
  const [top, setTop] = React.useState(514);

  const styles = {
    position: 'absolute',
    right: right,
    top: top,
  };

  const getRandom = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
  const _nelpastel = (e) => {
    console.log('hoverrr');
    var right = 0;
    var top = 0;
    right = getRandom(0, 600) + 'px'; // 👈🏼 Horizontally
    top = getRandom(0, 600) + 'px'; // 👈🏼 Vertically
    setRight(right);
    setTop(top);
  };

  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top' alt='dya' style={{ width: '15rem', height: 'auto' }} />
        <img src={cardImage2} class='card-img-top' alt='dya' style={{ width: 'auto', height: '24rem' }} />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>
          <strong>10. </strong>Que nervioooooos
        </h5>
        <p className='card-text text-left'>
          <h2 className='text-center'> Dya Angela Cervantes Gallo ❤️ ¿Quieres ser mi novia?</h2>
          <br />
          <br />
          <button type='button' className='btn btn-danger' style={styles} onMouseEnter={_nelpastel}>
            Nel pastel
          </button>
          {/* Button trigger modal */}
          <button type='button' className='btn btn-success' data-toggle='modal' data-target='#exampleModal'>
            Jaloooooo
          </button>
          {/* Modal */}
          <div className='modal fade' id='exampleModal' tabIndex={-1} aria-labelledby='exampleModalLabel' aria-hidden='true'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='exampleModalLabel'>
                    Sabía que ibas a decir que si 😎
                  </h5>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body text-center'>
                  <img src={logrado} class='card-img-top' alt='dya' style={{ width: '30rem', height: 'auto' }} />
                  <h3>¿Y ahora que?</h3>
                  <img src={plankton} class='card-img-top' alt='dya' style={{ width: '30rem', height: 'auto' }} />
                  <h2>Israel & Dya ❤️</h2>
                  <h3>19/07/2021</h3>
                  <h4>hoy es: {moment().format('L')}</h4>
                  <h4>
                    Así que somos novios desde: <br />
                    <span className='text-success'>{moment('19072021144100', 'DDMMYYYYHHmmss').fromNow()}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </p>
      </div>
    </div>
  );
};

export default Step1;
