import React from 'react';
import cardImage from '../assets/img/shrek-fiona.jpeg';

const Step1 = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top' alt='dya' />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>A new chapter beggins</h5>
        <h4 className='card-text'>Creo que salió bien eh? jajaja se te acabó la soltería micielaaaaaaa te quiero novia ❤️</h4>
      </div>
    </div>
  );
};

export default Step1;
