import React from 'react';
import cardImage from '../assets/img/Dya-14.JPG';
import cereal from '../assets/img/meme-cereal.png';

const Step1 = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top' alt='dya' />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>
          <strong>6. </strong>Living la vida loca
        </h5>
        <p className='card-text'>
          Y pos como no enamorarse de ti jajaja eres genial, la vdd no se si fue en navidad o en año nuevo cuando ME ESCUPISTEEEE!{' '}
          <img src={cereal} alt='cereal' />
          <br></br>jajajaja bueno, la cosa es que dije nel si me gusta jajaja y pues desde la primera vez que me di cuenta entré
          en conflicto porque no quería hacer, ni decir nada, no quería arruinar la amistad y confianza que tenía con todos.
        </p>
      </div>
    </div>
  );
};

export default Step1;
