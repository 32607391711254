import React from 'react';
import cardImage from '../assets/img/Dya-59.JPG';

const Step1 = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top' alt='dya' />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>
          <strong>3. </strong>Siempre en contacto
        </h5>
        <p className='card-text'>
          Después de eso, en realidad nunca dejamos de hablarnos jajaja aunque siempre fue bien x, como simples amigos,
          platicabamos de cosas bien tontas jajaja incluso tuvimos platicas interesantes sobre la vida, la mentalidad de las
          personas, lo que opinas de varios temas, y poco a poco me fui dando cuenta que eres una pequeña cajita de sorpresas.
        </p>
      </div>
    </div>
  );
};

export default Step1;
