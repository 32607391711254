import React from 'react';
import Sound from 'react-sound';
import song from '../assets/Accidentally-in-love.mp3';
import play from '../assets/boton-de-play.svg';
import pause from '../assets/boton-de-pausa.svg';

const PlaySong = (handleSongloading, handleSongPlaying, handleSongFinishedPlaying) => {
  const [playing, setPlaying] = React.useState(false);

  return (
    <div>
      <Sound
        url={song}
        playStatus={playing ? Sound.status.PLAYING : Sound.status.STOPPED}
        // playFromPosition={300}
        onLoading={handleSongloading}
        onPlaying={handleSongPlaying}
        onFinishedPlaying={handleSongFinishedPlaying}
        loop={true}
      />
      <input
        type='image'
        src={!playing ? play : pause}
        alt='play button'
        onClick={() => setPlaying(!playing)}
        style={{ width: '3rem', height: 'auto' }}
      />
    </div>
  );
};

export default PlaySong;
