import React from 'react';
import cardImage from '../assets/img/Thanoss-min.jpg';

const Step1 = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top' alt='dya' />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>
          <strong>4. </strong>Fue como thanos... inevitable xd
        </h5>
        <p className='card-text'>Jajajaja aquí nomas quería poner la referencia jajaja síguele</p>
      </div>
    </div>
  );
};

export default Step1;
