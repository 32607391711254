import React from 'react';
import './assets/styles/App.scss';
import Inicio from './components/Inicio';
import MuyAtras from './components/MuyAtras';
import PlaySong from './components/PlaySong';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';
import Step7 from './components/Step7';
import Step8 from './components/Step8';
import Step9 from './components/Step9';
import Step10 from './components/Step10';
import FinalStep from './components/FinalStep';

const App = () => {
  const [step, setStep] = React.useState(0);

  React.useEffect(() => {
    console.log('step actual: ', step);
  }, [step]);

  const _renderSteps = (step) => {
    switch (step) {
      case 0:
        return <Inicio />;
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      case 5:
        return <Step5 />;
      case 6:
        return <Step6 />;
      case 7:
        return <Step7 />;
      case 8:
        return <Step8 />;
      case 9:
        return <Step9 />;
      case 10:
        return <Step10 />;
      case 11:
        return <FinalStep />;
      default:
        return <MuyAtras />;
    }
  };
  return (
    <div id='login'>
      <div className='container-fluid'>
        <div id='login-row' className='row justify-content-center align-items-center'>
          <div id='login-box' className='col-md-12'>
            {_renderSteps(step)}
            <div className='btn-group center' role='group'>
              {step >= 0 && (
                <button type='button' className='btn btn-light' onClick={() => setStep(step - 1)}>
                  Atrás
                </button>
              )}
              {step < 11 && (
                <button type='button' className='btn btn-light' onClick={() => setStep(step + 1)}>
                  Siguiente
                </button>
              )}
              {step === 11 && (
                <button type='button' className='btn btn-light' onClick={() => setStep(0)}>
                  Volver a inicio
                </button>
              )}
            </div>
          </div>
          <PlaySong />
        </div>
      </div>
    </div>
  );
};

export default App;
