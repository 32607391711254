import React from 'react';
import cardImage from '../assets/img/Dya-10.JPG';
import cardImage2 from '../assets/img/Dya-32.JPG';

const Step1 = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top' alt='dya' style={{ width: '15rem', height: 'auto' }} />
        <img src={cardImage2} class='card-img-top' alt='dya' style={{ width: '15rem', height: 'auto' }} />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>
          <strong>7. </strong>Entre broma y broma
        </h5>
        <p className='card-text'>
          Así pasaron los meses y todo normal, cada quien seguía con su vida, pero bien dicen que uno pone y dios dispone jajaja
          así que por ahí de abril empezamos a platicar más y más y estaba entretenida la plática, no me aburría de hablar
          contigo. Y pues entre broma y broma resultó que me confesaste que yo tmb te gustaba y fue de whaaaaaaaaat?
          khastapasandaaaaaaaaa xD
          <strong>PD: la canción se acaba entre este y el siguiente paso, así que pícale play de nuevo</strong>
        </p>
      </div>
    </div>
  );
};

export default Step1;
