import React from 'react';
import cardImage from '../assets/img/Dya-18.JPG';

const Step1 = () => {
  return (
    <div className='card'>
      <h5 className='card-header'>
        <img src={cardImage} class='card-img-top' alt='dya' />
      </h5>
      <div className='card-body'>
        <h5 className='card-title'>
          <strong>2. </strong>Historia...
        </h5>
        <p className='card-text'>
          Bueno, comenzaré con un pequeño resumen... Todo comenzó hace un par de años cuando fue la fiesta de tus Xv's, creo que
          fue ahí la primera vez que te vi diferente, lucías tan perfecta, y bailar el caballo dorado en la tarima jajaja se me
          olvidó la pena porque estaba contigo. Honestamente me sacó de onda darme cuenta que te vi diferente, y lo ignoré, por
          supuesto que no me lo esperaba y tampoco pensaba decir ni hacer nada.
        </p>
      </div>
    </div>
  );
};

export default Step1;
